<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Declaración de aplicabilidad</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarIso27001)">
                            <b-row>
                                <b-col md="1"></b-col>
                                <b-col md="10">
                                    <b-row>
                                        <b-col md="12" class="mb-3">
                                            <span class="h5"> Datos generales</span>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="fecha de auditoria" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha de auditoria:" class="mb-2">
                                                    <b-form-input  v-model.lazy="datosIso27001.fechaAuditoria" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="autor" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Autor:" class="mb-2">
                                                    <b-form-input v-model.lazy="datosIso27001.autor" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el autor"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="auditor jefe" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Auditor jefe:" class="mb-2">
                                                    <b-form-input v-model.lazy="datosIso27001.auditorJefe" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el auditor jefe"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="auditor" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Auditor:" class="mb-2">
                                                    <b-form-input v-model.lazy="datosIso27001.auditor" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el auditor"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6" class="mt-3">
                                            <span>Por favor, complete a continuación el siguiente CUESTIONARIO, teniendo en cuenta que en
                                                cada apartado auditado podrá seleccionar entre:</span>
                                            <ul class="mt-2">
                                                <li>
                                                    <strong>OK:</strong> Apartado correcto. Vd. ha verificado, mediante evidencias, que la empresa está llevando a cabo dicho apartado de forma correcta.
                                                </li>
                                                <li>
                                                    <strong>NA:</strong> No aplica.
                                                </li>
                                            </ul>
                                        </b-col>
                                        <b-col md="6" class="my-3">
                                            <span>
                                                Puede realizar la aplicación en diferentes días y momentos; para ello, vaya guardando la
                                                información mediante el botón <strong>Registrar Formulario</strong> y, una vez finalizada,
                                                proceda a <strong>Imprimir Informe</strong> para generar el mismo y entregarlo a su cliente.
                                            </span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="1"></b-col>

                                <b-col md="12">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-1 class="bg-accordion">
                                                    <span class="h6"> A.5 Política de la seguridad de la información </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.5.1. ORIENTACIÓN DE LA DIRECCIÓN PARA LA GESTIÓN DE LA SEGURIDAD DE LA INFORMACIÓN.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. BRINDAR ORIENTACIÓN Y SOPORTE, POR PARTE DE LA DIRECCIÓN, DE ACUERDO CON LOS REQUISITOS DEL NEGOCIO Y CON LAS LEYES Y REGLAMENTOS PERTINENTES.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.5.1.1. Políticas para la Seguridad de la Información. Se debe definir un conjunto de políticas para la seguridad de la información, aprobada por la Dirección, publicada y comunicada a los empleados y partes interesadas.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[0][0].resultado" @change="datosIso27001.listaPuntosNorma[0][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[0][0].observaciones" @change="datosIso27001.listaPuntosNorma[0][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.5.1.2. Revisión de las Políticas para seguridad de la información. Las Políticas para Seguridad de la Información se deben revisar a intervalos planificados o si ocurren cambios significativos, para asegurar su idoneidad, adecuación y eficacia continúas.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[0][1].resultado" @change="datosIso27001.listaPuntosNorma[0][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[0][1].observaciones" @change="datosIso27001.listaPuntosNorma[0][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-2 class="bg-accordion">
                                                    <span class="h6"> A.6 Organización de la seguridad de la información </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.6.1. ORGANIZACIÓN INTERNA.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ESTABLECER UN MARCO DE REFERENCIA DE GESTIÓN PARA INICIAR Y CONTROLAR LA IMPLEMENTACIÓN Y OPERACIÓN DEL SGSI.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.1.1. Seguridad de la Información Roles y Responsabilidades. Se deben definir y asignar todas las responsabilidades de la seguridad de la información.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][0].resultado" @change="datosIso27001.listaPuntosNorma[1][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][0].observaciones" @change="datosIso27001.listaPuntosNorma[1][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.1.2. Separación de deberes. Las tareas y áreas de responsabilidad en conflicto se deben separar para reducir las posibilidades de modificación no autorizada o no intencional o el uso indebido de los activos de la organización.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][1].resultado" @change="datosIso27001.listaPuntosNorma[1][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][1].observaciones" @change="datosIso27001.listaPuntosNorma[1][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.1.3. Contacto con las autoridades. Se debe mantener contactos apropiados con las autoridades pertinentes.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][2].resultado" @change="datosIso27001.listaPuntosNorma[1][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][2].observaciones" @change="datosIso27001.listaPuntosNorma[1][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.1.4. Contacto con grupos de interés especial. Se deben mantener controles apropiados con grupos de interés
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][3].resultado" @change="datosIso27001.listaPuntosNorma[1][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][3].observaciones" @change="datosIso27001.listaPuntosNorma[1][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.1.5. Seguridad de la información en Gestión de Proyectos. La seguridad de la información se debe tratar en la gestión de proyectos, independiente del tipo de proyecto.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][4].resultado" @change="datosIso27001.listaPuntosNorma[1][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][4].observaciones" @change="datosIso27001.listaPuntosNorma[1][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.6.2. DISPOSITIVOS MÓVILES Y TELETRABAJO.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. GARANTIZAR LA SEGURIDAD DEL TELETRABAJO Y EL USO DE DISPOSITIVOS MÓVILES.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.2.1. Política para dispositivos móviles. Se deben adoptar una política y unas medidas de seguridad de soporte, para gestionar los riesgos introducidos por el uso de dispositivos móviles.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][5].resultado" @change="datosIso27001.listaPuntosNorma[1][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][5].observaciones" @change="datosIso27001.listaPuntosNorma[1][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.6.2.2. Teletrabajo. Se deben implementar una política y medidas de seguridad de soporte para proteger la información a la que se tiene acceso, que es procesada o almacenada en los lugares en los que se realiza teletrabajo.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[1][6].resultado" @change="datosIso27001.listaPuntosNorma[1][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[1][6].observaciones" @change="datosIso27001.listaPuntosNorma[1][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-3 class="bg-accordion">
                                                    <span class="h6"> A.7 Seguridad de los recursos humanos</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.7.1. ANTES DE ASUMIR EL EMPLEO.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR QUE LOS EMPLEADOS Y CONTRATISTAS COMPRENDEN SUS RESPONSABILIDADES Y SON IDÓNEOS EN LOS ROLES PARA LOS QUE SE CONSIDERAN.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.1.1. Selección. Las verificaciones de los antecedentes de todos los candidatos a un empleo se deben llevar a cabo de acuerdo con las leyes, reglamentos y ética pertinentes, y deben ser proporcionales a los requisitos de negocio, a la clasificación de la información a que se va a tener acceso, y a los riesgos percibidos.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][0].resultado" @change="datosIso27001.listaPuntosNorma[2][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][0].observaciones" @change="datosIso27001.listaPuntosNorma[2][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.1.2. Términos y condiciones del empleo. Los acuerdos contractuales con empleados y contratistas deben establecer sus responsabilidades y las de la organización en cuanto a seguridad de la información.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][1].resultado" @change="datosIso27001.listaPuntosNorma[2][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][1].observaciones" @change="datosIso27001.listaPuntosNorma[2][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.7.2. DURANTE LA EJECUCIÓN DEL EMPLEO.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURARSE QUE LOS EMPLEADOS Y CONTRATISTAS TOMEN CONCIENCIA DE SUS RESPONSABILIDADES DE SEGURIDAD DE LA INFORMACIÓN Y LAS CUMPLAN.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.2.1. Responsabilidades de la Dirección. La dirección debe exigir a todos los empleados y contratistas la aplicación de la seguridad de la información de acuerdo con las políticas y procedimientos establecidos de la organización.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][2].resultado" @change="datosIso27001.listaPuntosNorma[2][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][2].observaciones" @change="datosIso27001.listaPuntosNorma[2][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.2.2. Toma de conciencia, educación y formación de la Seguridad de la Información. Todos los empleados de la organización y donde sea pertinente, los contratistas deben recibir educación y la formación en toma de conciencia apropiada, y actualizaciones regulares sobre las políticas y procedimientos pertinentes para su cargo.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][3].resultado" @change="datosIso27001.listaPuntosNorma[2][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][3].observaciones" @change="datosIso27001.listaPuntosNorma[2][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.2.3. Proceso disciplinario. Se debe contar con un proceso formal y comunicado para emprender acciones contra empleados que hayan cometido una violación a la seguridad de la información.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][4].resultado" @change="datosIso27001.listaPuntosNorma[2][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][4].observaciones" @change="datosIso27001.listaPuntosNorma[2][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.7.3. TERMINACIÓN Y CAMBIO DE EMPLEO.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PROTEGER LOS INTERESES DE LA ORGANIZACIÓN COMO PARTE DEL PROCESO DE CAMBIO O TERMINACIÓN DEL EMPLEO
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.7.3.1. Terminación o cambio de responsabilidades de empleo. Las responsabilidades y los deberes de seguridad de la información que permanecen válidos después de la terminación o cambio de empleo se deben definir, comunicar al empleado o contratista y se deben hacer cumplir.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[2][5].resultado" @change="datosIso27001.listaPuntosNorma[2][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[2][5].observaciones" @change="datosIso27001.listaPuntosNorma[2][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-4 class="bg-accordion">
                                                    <span class="h6"> A.8 Gestión de activos </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.8.1. RESPONSABILIDAD POR LOS ACTIVOS.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. IDENTIFICAR LOS ACTIVOS ORGANIZACIONALES Y DEFINIR LAS RESPONSABILIDADES DE PROTECCIÓN APROPIADA.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.1.1. Inventario de Activos. Se deben identificar los activos asociados con información e instalaciones de procesamiento de información, y se debe elaborar y mantener un inventario de estos activos.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][0].resultado" @change="datosIso27001.listaPuntosNorma[3][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][0].observaciones" @change="datosIso27001.listaPuntosNorma[3][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.1.2. Propiedad de los activos. Los activos mantenidos en el inventario deben ser propios.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][1].resultado" @change="datosIso27001.listaPuntosNorma[3][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][1].observaciones" @change="datosIso27001.listaPuntosNorma[3][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.1.3. Uso Aceptable de los Activos. Se deben identificar, documentar e implementar reglas para el uso aceptable de información y de activos asociados con información e instalaciones de procesamiento de información.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][2].resultado" @change="datosIso27001.listaPuntosNorma[3][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][2].observaciones" @change="datosIso27001.listaPuntosNorma[3][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.1.4. Devolución de Activos. Todos los empleados y usuarios de partes externas deben devolver todos los activos de la organización que se encuentren a su cargo, al terminar su empleo, contrato o acuerdo.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][3].resultado" @change="datosIso27001.listaPuntosNorma[3][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][3].observaciones" @change="datosIso27001.listaPuntosNorma[3][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.8.2. CLASIFICACIÓN DE LA INFORMACIÓN.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR QUE LA ORGANIZACIÓN RECIBE UN NIVEL APROPIADO DE PROTECCIÓN DE ACUERDO CON SU IMPORTANCIA PARA LA ORGANIZACIÓN.
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.2.1. Clasificación de la Información. La información se debe clasificar en función de los requisitos legales, valor, criticidad y susceptibilidad a divulgación o a modificación no autorizada.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][4].resultado" @change="datosIso27001.listaPuntosNorma[3][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][4].observaciones" @change="datosIso27001.listaPuntosNorma[3][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.2.2. Etiquetado de la Información. Se debe desarrollar e implementar un conjunto apropiado de procedimientos para el etiquetado de la información, de acuerdo con el esquema de clasificación de información adoptado por la organización.
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][5].resultado" @change="datosIso27001.listaPuntosNorma[3][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][5].observaciones" @change="datosIso27001.listaPuntosNorma[3][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.2.3. Manejo de Activos. Se deben desarrollar e implementar procedimientos para el manejo de activos, de acuerdo con el esquema de clasificación de información adoptado por la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][6].resultado" @change="datosIso27001.listaPuntosNorma[3][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][6].observaciones" @change="datosIso27001.listaPuntosNorma[3][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.8.3. MANEJO DE MEDIOS DE SOPORTE. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PREVENIR LA DIVULGACIÓN, LA MODIFICACIÓN, EL RETIRO O LA DESTRUCCIÓN DE INFORMACIÓN ALMACENADA EN MEDIOS DE SOPORTE. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.3.1. Gestión de medios de Soporte Removibles. Se deben implementar procedimientos para la gestión de medios de soporte removibles, de acuerdo con el esquema de clasificación adoptado por la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][7].resultado" @change="datosIso27001.listaPuntosNorma[3][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][7].observaciones" @change="datosIso27001.listaPuntosNorma[3][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.3.2. Disposición de los medios de soporte. Se debe disponer en forma segura de los medios de soporte cuando ya no se requieran, utilizando procedimientos formales. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][8].resultado" @change="datosIso27001.listaPuntosNorma[3][8].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][8].observaciones" @change="datosIso27001.listaPuntosNorma[3][8].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.8.3.3. Transferencia de medios de soporte físicos. Los medios que contienen información se deben proteger contra acceso no autorizado, uso indebido o corrupción durante el transporte. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[3][9].resultado" @change="datosIso27001.listaPuntosNorma[3][9].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[3][9].observaciones" @change="datosIso27001.listaPuntosNorma[3][9].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-5 class="bg-accordion">
                                                    <span class="h6"> A.9 Control de acceso</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.9.1. REQUISITOS DEL NEGOCIO PARA CONTROL DE ACCESO. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. LIMITAR EL ACCESO A INFORMACIÓN Y A INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.1.1. Política de Control de Acceso. Se debe establecer, documentar y revisar una política de control de acceso con base en los requisitos del negocio y de seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][0].resultado" @change="datosIso27001.listaPuntosNorma[4][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][0].observaciones" @change="datosIso27001.listaPuntosNorma[4][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.1.2. Acceso a redes y a servicios en red. Solo se debe permitir acceso de los usuarios a la red y a los servicios de red para los que hayan sido autorizados específicamente. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][1].resultado" @change="datosIso27001.listaPuntosNorma[4][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][1].observaciones" @change="datosIso27001.listaPuntosNorma[4][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.9.2. GESTIÓN DE ACCESO DE USUARIOS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR EL ACCESO DE LOS USUARIOS AUTORIZADOS E IMPEDIR EL ACCESO NO AUTORIZADO A SISTEMAS Y SERVICIOS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.1. Registro y cancelación del registro de usuarios. Se debe implementar un proceso formal de registro y de cancelación del registro para posibilitar la asignación de los derechos de acceso. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][2].resultado" @change="datosIso27001.listaPuntosNorma[4][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][2].observaciones" @change="datosIso27001.listaPuntosNorma[4][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.2. Suministro de acceso de usuarios. Se debe implementar un proceso de suministro de acceso formal de usuarios para asignar o cancelar los derechos de acceso a todo tipo de usuarios para todos los sistemas y servicios. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][3].resultado" @change="datosIso27001.listaPuntosNorma[4][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][3].observaciones" @change="datosIso27001.listaPuntosNorma[4][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.3. Gestión de derechos de acceso privilegiado. Se debe restringir y controlar la asignación y uso de derechos de acceso privilegiado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][4].resultado" @change="datosIso27001.listaPuntosNorma[4][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][4].observaciones" @change="datosIso27001.listaPuntosNorma[4][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.4. Gestión de información de autenticación secreta de usuarios. La asignación de información de autenticación secreta se debe controlar por medio de un procedimiento de gestión formal. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][5].resultado" @change="datosIso27001.listaPuntosNorma[4][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][5].observaciones" @change="datosIso27001.listaPuntosNorma[4][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.5. Revisión de los derechos de acceso de usuarios. Los dueños de los activos deben revisar los derechos de acceso de los usuarios a intervalos regulares. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][6].resultado" @change="datosIso27001.listaPuntosNorma[4][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][6].observaciones" @change="datosIso27001.listaPuntosNorma[4][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.2.6. Cancelación o ajuste de los derechos de acceso. Los derechos de acceso de todos los empleados y de usuarios externos a la información y a las instalaciones de procedimiento de información se deben cancelar al terminar su empleo, contrato o acuerdo, o se deben ajustar cuando se hagan cambios. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][7].resultado" @change="datosIso27001.listaPuntosNorma[4][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][7].observaciones" @change="datosIso27001.listaPuntosNorma[4][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.9.3. RESPONSABILIDADES DE LOS USUARIOS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. HACER QUE LOS USUARIOS RINDAN CUENTAS POR LA CUSTODIA DE SU INFORMACIÓN DE AUTENTICACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.3.1.Uso de información secreta. Se debe exigir a los usuarios que cumplan las prácticas de la organización para el uso de información de autenticación secreta. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][8].resultado" @change="datosIso27001.listaPuntosNorma[4][8].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][8].observaciones" @change="datosIso27001.listaPuntosNorma[4][8].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.9.4. CONTROL DE ACCESO A SISTEMAS Y APLICACIONES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PREVENIR EL USO NO AUTORIZADO DE SISTEMAS Y APLICACIONES. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.4.1. Restricción de acceso a información. El acceso a la información y a las funciones de los sistemas de las aplicaciones se debe restringir de acuerdo con la política de control de acceso. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][9].resultado" @change="datosIso27001.listaPuntosNorma[4][9].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][9].observaciones" @change="datosIso27001.listaPuntosNorma[4][9].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.4.2. Procedimiento de Conexión Segura. Cuando lo requiere la política de control de acceso, el acceso a sistemas y aplicaciones se debe controlar mediante un proceso de conexión segura. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][10].resultado" @change="datosIso27001.listaPuntosNorma[4][10].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][10].observaciones" @change="datosIso27001.listaPuntosNorma[4][10].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.4.3. Sistema de Gestión de Contraseñas.los sistemas de gestión de contraseñas deben ser interactivos y deben asegurar contraseñas de calidad. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][11].resultado" @change="datosIso27001.listaPuntosNorma[4][11].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][11].observaciones" @change="datosIso27001.listaPuntosNorma[4][11].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.4.4. Uso de programas utilitarios privilegiados. Se debe restringir y controlar estrechamente el uso de programas utilitarios que podrían tener capacidad de anular el sistema y los controles de las aplicaciones. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][12].resultado" @change="datosIso27001.listaPuntosNorma[4][12].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][12].observaciones" @change="datosIso27001.listaPuntosNorma[4][12].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.9.4.5. Control de Acceso a Códigos Fuente de Programas. Se debe restringir el acceso a códigos fuente de programas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[4][13].resultado" @change="datosIso27001.listaPuntosNorma[4][13].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[4][13].observaciones" @change="datosIso27001.listaPuntosNorma[4][13].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-6 class="bg-accordion">
                                                    <span class="h6"> A.10 Criptografía </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.10.1. CONTROLES CRIPTOGRÁFICOS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR EL USO APROPIADO Y EFICAZ DE LA CRIPTOGRAFÍA PARA PROTEGER LA CONFIABILIDAD, LA AUTENTICIDAD Y/O LA INTEGRIDAD DE LA INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.10.1.1. Política sobre el uso de controles Criptográficos. Se debe desarrollar e implementar una política sobre el uso de controles criptográficos para protección de información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[5][0].resultado" @change="datosIso27001.listaPuntosNorma[5][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[5][0].observaciones" @change="datosIso27001.listaPuntosNorma[5][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.10.1.2. Gestión de Claves. Se debe desarrollar e implementar una política sobre el uso, protección y tiempo de vida de claves criptográficas, durante todo su ciclo de vida. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[5][1].resultado" @change="datosIso27001.listaPuntosNorma[5][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[5][1].observaciones" @change="datosIso27001.listaPuntosNorma[5][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-7 class="bg-accordion">
                                                    <span class="h6"> A.11 Seguridad física y ambiental </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.11.1. ÁREAS SEGURAS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PREVENIR EL ACCESO FÍSICO NO AUTORIZADO, EL DAÑO Y LA INTERFERENCIA A LA INFORMACIÓN Y A LAS INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN DE LA ORGANIZACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.1. Perímetro de Seguridad Física. Se deben definir y usar perímetros de seguridad, y usarlos para proteger áreas que contengan información confidencial o crítica, e instalaciones de manejo de información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][0].resultado" @change="datosIso27001.listaPuntosNorma[6][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][0].observaciones" @change="datosIso27001.listaPuntosNorma[6][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.2. Controles Físicos de entrada. Las áreas seguras se deben proteger mediante controles de entrada apropiados para asegurar que solamente se permite el acceso a personal autorizado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][1].resultado" @change="datosIso27001.listaPuntosNorma[6][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][1].observaciones" @change="datosIso27001.listaPuntosNorma[6][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.3. Seguridad de oficinas, salones e instalaciones. Se debe diseñar y aplicar seguridad física a oficinas, salones e instalaciones. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][2].resultado" @change="datosIso27001.listaPuntosNorma[6][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][2].observaciones" @change="datosIso27001.listaPuntosNorma[6][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.4. Protección contra amenazas externas y ambientales. Se debe diseñar y aplicar protección física contra desastres naturales, ataques maliciosos o accidentes. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][3].resultado" @change="datosIso27001.listaPuntosNorma[6][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][3].observaciones" @change="datosIso27001.listaPuntosNorma[6][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.5. Trabajo en áreas seguras. Se deben diseñar y aplicar procedimientos para trabajo en áreas seguras. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][4].resultado" @change="datosIso27001.listaPuntosNorma[6][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][4].observaciones" @change="datosIso27001.listaPuntosNorma[6][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.1.6. Áreas de despacho y carga. Se deben controlar los puntos de acceso tales como áreas de despacho y de carga y otros puntos en donde pueden entrar personas no autorizadas, y si es posible, aislarlos de las instalaciones de procesamiento de información para evitar el acceso no autorizado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][5].resultado" @change="datosIso27001.listaPuntosNorma[6][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][5].observaciones" @change="datosIso27001.listaPuntosNorma[6][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.11.2. EQUIPOS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PREVENIR LA PÉRDIDA, DAÑO, ROBO O COMPROMISO DE ACTIVOS, Y LA INTERRUPCIÓN DE LAS OPERACIONES DE LA ORGANIZACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.1. Ubicación y protección de los equipos. Los equipos deben estar ubicados y protegidos para reducir los riesgos de amenazas y peligros ambientales y las posibilidades de acceso no autorizado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][6].resultado" @change="datosIso27001.listaPuntosNorma[6][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][6].observaciones" @change="datosIso27001.listaPuntosNorma[6][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.2. Servicios Públicos de soporte. Los equipos se deben proteger de fallas de potencia y otras interrupciones causadas por fallas en los servicios públicos de soporte. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][7].resultado" @change="datosIso27001.listaPuntosNorma[6][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][7].observaciones" @change="datosIso27001.listaPuntosNorma[6][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.3. Seguridad del cableado. El cableado de potencia y de telecomunicaciones que porta datos o brinda soporte a los servicios de información se debe proteger contra interceptaciones, interferencia o daño. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][8].resultado" @change="datosIso27001.listaPuntosNorma[6][8].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][8].observaciones" @change="datosIso27001.listaPuntosNorma[6][8].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.4. Mantenimiento de equipos. Los equipos se deben mantener correctamente para asegurar su disponibilidad e integridad continuas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][9].resultado" @change="datosIso27001.listaPuntosNorma[6][9].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][9].observaciones" @change="datosIso27001.listaPuntosNorma[6][9].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.5. Retiro de Activos. Los equipos, información o software no se deben retirar de su sitio sin autorización previa. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][10].resultado" @change="datosIso27001.listaPuntosNorma[6][10].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][10].observaciones" @change="datosIso27001.listaPuntosNorma[6][10].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.6. Seguridad de equipos y activos fuera del predio. Se deben aplicar medidas de seguridad a los activos que se encuentran fuera de los predios de la organización, teniendo en cuenta los diferentes riesgos de trabajar fuera de dichos predios. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][11].resultado" @change="datosIso27001.listaPuntosNorma[6][11].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][11].observaciones" @change="datosIso27001.listaPuntosNorma[6][11].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.7. Disposición segura o reutilización de equipos. Se deben verificar todos los elementos de equipos que contengan medios de almacenamiento para asegurar que cualquier dato confidencial o software con licencia haya sido retirado o sobre escrito en forma segura antes de su disposición o reuso. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][12].resultado" @change="datosIso27001.listaPuntosNorma[6][12].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][12].observaciones" @change="datosIso27001.listaPuntosNorma[6][12].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.8. Equipos sin supervisión de los usuarios. Los usuarios deben asegurarse de que el equipo sin supervisión tenga la protección apropiada. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][13].resultado" @change="datosIso27001.listaPuntosNorma[6][13].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][13].observaciones" @change="datosIso27001.listaPuntosNorma[6][13].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.11.2.9. Política de escritorio limpio y pantalla limpia. Se debe adoptar una política de escritorio limpio para los papeles y medios de almacenamiento removibles, y una política de pantalla limpia para las instalaciones de procesamiento de información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[6][14].resultado" @change="datosIso27001.listaPuntosNorma[6][14].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[6][14].observaciones" @change="datosIso27001.listaPuntosNorma[6][14].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-8 class="bg-accordion">
                                                    <span class="h6"> A.12 Seguridad de las operaciones </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.1. PROCEDIMIENTOS OPERACIONALES Y RESPONSABILIDADES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR LAS OPERACIONES CORRECTAS Y SEGURAS DE LAS INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.1.1. Procedimientos de operación documentadas. Los procedimientos operativos se deben documentar y poner a disposición de todos los usuarios que los necesitan. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][0].resultado" @change="datosIso27001.listaPuntosNorma[7][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][0].observaciones" @change="datosIso27001.listaPuntosNorma[7][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.1.2. Gestión de Cambios. Se deben controlar los cambios en la organización, en los procesos de negocio, en las instalaciones y en los sistemas de procesamiento de información que afectan la seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][1].resultado" @change="datosIso27001.listaPuntosNorma[7][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][1].observaciones" @change="datosIso27001.listaPuntosNorma[7][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.1.3. Gestión de Capacidad. Se debe hacer seguimiento al uso de recursos, hacer los ajustes, y hacer proyecciones de los requisitos de capacidad futura, para asegurar el desempeño requerido del sistema. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][2].resultado" @change="datosIso27001.listaPuntosNorma[7][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][2].observaciones" @change="datosIso27001.listaPuntosNorma[7][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.1.4. Separación de los ambientes de desarrollo, ensayo y operación. Se deben separar los ambientes de desarrollo, ensayo y operativos, para reducir los riesgos de acceso o cambios no autorizados al ambiente operacional. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][3].resultado" @change="datosIso27001.listaPuntosNorma[7][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][3].observaciones" @change="datosIso27001.listaPuntosNorma[7][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.2. PROTECCIÓN CONTRA CÓDIGOS MALICIOSOS. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURARSE DE QUE LA INFORMACIÓN Y LAS INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN ESTÉN PROTEGIDAS CONTRA CÓDIGOS MALICIOSOS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.2.1. Controles contra códigos maliciosos. Se deben implementar controles de detección, de prevención y de recuperación, combinarlos con la toma de conciencia apropiada de los usuarios, para proteger contra códigos maliciosos. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][4].resultado" @change="datosIso27001.listaPuntosNorma[7][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][4].observaciones" @change="datosIso27001.listaPuntosNorma[7][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.3. COPIAS DE RESPALDO. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PROTEGER CONTRA LA PÉRDIDA DE DATOS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.3.1. Copias de respaldo de la información. Se deben hacer copias de respaldo de la información, software e imágenes de los sistemas y ponerlas a prueba regularmente de acuerdo con una política de copias de respaldo acordadas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][5].resultado" @change="datosIso27001.listaPuntosNorma[7][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][5].observaciones" @change="datosIso27001.listaPuntosNorma[7][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.4. REGISTRO Y SEGUIMIENTO. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. REGISTRAR EVENTOS Y GENERAR EVIDENCIA. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.4.1. Registro de eventos. Se deben elaborar, conservar y revisar regularmente los registros de eventos acerca de actividades del usuario, excepcionales, fallas y eventos de seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][6].resultado" @change="datosIso27001.listaPuntosNorma[7][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][6].observaciones" @change="datosIso27001.listaPuntosNorma[7][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.4.2. Protección de la información de registro. Las instalaciones y la información de registro se deben proteger contra alteración y acceso no autorizado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][7].resultado" @change="datosIso27001.listaPuntosNorma[7][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][7].observaciones" @change="datosIso27001.listaPuntosNorma[7][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.4.3. Registros del administrador y del operador. Las actividades del administrador y del operador del sistema se deben registrar y los registros se deben proteger y revisar con regularidad. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][8].resultado" @change="datosIso27001.listaPuntosNorma[7][8].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][8].observaciones" @change="datosIso27001.listaPuntosNorma[7][8].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.4.4. Sincronización de relojes. Los relojes de todos los sistemas de procesamiento de información pertinentes dentro de una organización o ámbito de seguridad se deben sincronizar con una única fuente de referencia de tiempo. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][9].resultado" @change="datosIso27001.listaPuntosNorma[7][9].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][9].observaciones" @change="datosIso27001.listaPuntosNorma[7][9].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.5. CONTROL DE SOFTWARE OPERACIONAL. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURARSE DE LA INTEGRIDAD DE LOS SISTEMAS OPERACIONALES. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.5.1. Instalación de software en sistemas operativos. Se deben implementar procedimientos para controlar la instalación de software en sistemas operativos. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][10].resultado" @change="datosIso27001.listaPuntosNorma[7][10].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][10].observaciones" @change="datosIso27001.listaPuntosNorma[7][10].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.6. GESTIÓN DE VULNERABILIDAD TÉCNICA. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. PREVENIR EL APROVECHAMIENTO DE LAS VULNERABILIDADES TÉCNICAS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.6.1. Gestión de las vulnerabilidades técnicas. Se debe obtener oportunamente información acerca de las vulnerabilidades técnicas de los sistemas de información que se usen; evaluar la exposición de la organización a estas vulnerabilidades, y tomar las medidas apropiadas para tratar el riesgo asociado. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][11].resultado" @change="datosIso27001.listaPuntosNorma[7][11].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][11].observaciones" @change="datosIso27001.listaPuntosNorma[7][11].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.6.2. Restricciones sobre la instalación de Software. Se debe establecer e implementar el reglamento de instalación de software por parte de los usuarios. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][12].resultado" @change="datosIso27001.listaPuntosNorma[7][12].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][12].observaciones" @change="datosIso27001.listaPuntosNorma[7][12].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.12.7. CONSIDERACIONES SOBRE AUDITORÍAS DE SISTEMAS DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. MINIMIZAR EL IMPACTO DE LAS ACTIVIDADES DE AUDITORÍA SOBRE LOS SISTEMAS OPERATIVOS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.12.7.1. Controles sobre auditorías de Sistemas de Información. Los requisitos y actividades de auditoría que involucran la verificación de los sistemas operativos se deben planificar y acordar cuidadosamente para minimizar las interrupciones en los procesos del negocio. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[7][13].resultado" @change="datosIso27001.listaPuntosNorma[7][13].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[7][13].observaciones" @change="datosIso27001.listaPuntosNorma[7][13].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-9 class="bg-accordion">
                                                    <span class="h6"> A.13 Seguridad de las comunicaciones</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.13.1. GESTIÓN DE SEGURIDAD DE REDES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR LA PROTECCIÓN DE LA INFORMACIÓN EN LAS REDES, Y SUS INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN DE SOPORTE. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.1.1. Controles de redes. Las redes se deben gestionar y controlar para proteger la información en sistemas y aplicaciones. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][0].resultado" @change="datosIso27001.listaPuntosNorma[8][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][0].observaciones" @change="datosIso27001.listaPuntosNorma[8][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.1.2. Seguridad de los servicios de red. Se deben identificar los mecanismos de seguridad y los niveles de servicio y los requisitos de gestión de todos los servicios de red, e incluirlos en los acuerdos de servicios de red, e incluirlos en los acuerdos de servicio de red, ya sea que los servicios se presten internamente o se contraten externamente. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][1].resultado" @change="datosIso27001.listaPuntosNorma[8][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][1].observaciones" @change="datosIso27001.listaPuntosNorma[8][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.1.3. Separación en las redes. Los grupos de servicios de información, usuarios y sistemas de información se deben separar en las redes. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][2].resultado" @change="datosIso27001.listaPuntosNorma[8][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][2].observaciones" @change="datosIso27001.listaPuntosNorma[8][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.13.2. TRANSFERENCIA DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. MANTENER LA SEGURIDAD DE LA INFORMACIÓN TRANSFERIDA DENTRO DE UNA ORGANIZACIÓN Y CON CUALQUIER ENTIDAD EXTERNA. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.2.1. Políticas y procedimientos de transferencia de información. Se debe contar con políticas, procedimientos y controles de transferencia formales para proteger la transferencia de información, mediante el uso de todo tipo de instalaciones de comunicaciones. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][3].resultado" @change="datosIso27001.listaPuntosNorma[8][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][3].observaciones" @change="datosIso27001.listaPuntosNorma[8][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.2.2. Acuerdos sobre transferencia de información. Los acuerdos deben tratar la transferencia segura de información del negocio entre la organización y las partes externas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][4].resultado" @change="datosIso27001.listaPuntosNorma[8][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][4].observaciones" @change="datosIso27001.listaPuntosNorma[8][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.2.3. Mensajes electrónicos. Se debe proteger apropiadamente la información incluida en los mensajes electrónicos. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][5].resultado" @change="datosIso27001.listaPuntosNorma[8][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][5].observaciones" @change="datosIso27001.listaPuntosNorma[8][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.13.2.4. Acuerdos de confidencialidad o de no divulgación. Se deben identificar, revisar regularmente y documentar los requisitos para los acuerdos de confidencialidad o no divulgación que reflejen las necesidades de la organización para la protección de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[8][6].resultado" @change="datosIso27001.listaPuntosNorma[8][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[8][6].observaciones" @change="datosIso27001.listaPuntosNorma[8][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-10 class="bg-accordion">
                                                    <span class="h6"> A.14 Adquisición, desarrollo y mantenimiento de sistemas </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.14.1. REQUISITOS DE SEGURIDAD DE LOS SISTEMAS DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. GARANTIZAR QUE LA SEGURIDAD DE LA INFORMACIÓN SEA UNA PARTE INTEGRAL DE LOS SISTEMAS DE INFORMACIÓN DURANTE TODO EL CICLO DE VIDA. ESTO INCLUYE LOS REQUISITOS PARA SISTEMAS DE INFORMACIÓN QUE PRESTAN SERVICIOS SOBRE REDES PÚBLICAS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.1.1. Análisis y especificación de requisitos de seguridad de la información. Los requisitos relacionados con seguridad de la información se deben incluir en los requisitos para nuevos sistemas de información o para mejoras a los sistemas de información existentes. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][0].resultado" @change="datosIso27001.listaPuntosNorma[9][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][0].observaciones" @change="datosIso27001.listaPuntosNorma[9][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.1.2. Seguridad de servicios de las aplicaciones en redes públicas. La información involucrada en servicios de aplicaciones que pasan sobre redes públicas se debe proteger de actividades fraudulentas, disputas contractuales y divulgación y modificación no autorizadas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][1].resultado" @change="datosIso27001.listaPuntosNorma[9][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][1].observaciones" @change="datosIso27001.listaPuntosNorma[9][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.1.3. Protección de transacciones de servicios de aplicaciones. La información involucrada en las transacciones de servicios de aplicaciones se debe proteger para prevenir la transmisión incompleta, el enrutamiento errado, la alteración no autorizada de mensajes. La divulgación no autorizada y la duplicación o reproducción de mensajes no autorizados. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][2].resultado" @change="datosIso27001.listaPuntosNorma[9][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][2].observaciones" @change="datosIso27001.listaPuntosNorma[9][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.14.2. SEGURIDAD EN LOS PROCESOS DE DESARROLLO Y DE SOPORTE. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR QUE LA SEGURIDAD DE LA INFORMACIÓN ESTÉ DISEÑADA E IMPLEMENTADA DENTRO DEL CICLO DE VIDA DE DESARROLLO DE LOS SISTEMAS DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.1. Política de desarrollo seguro. Se deben establecer y aplicar reglas para el desarrollo de software y de sistemas a los desarrollos dentro de la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][3].resultado" @change="datosIso27001.listaPuntosNorma[9][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][3].observaciones" @change="datosIso27001.listaPuntosNorma[9][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.2. Procedimiento de control de cambios en sistemas. Los cambios a los sistemas dentro del ciclo de vida de desarrollo de software y de sistemas a los desarrollos dentro de la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][4].resultado" @change="datosIso27001.listaPuntosNorma[9][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][4].observaciones" @change="datosIso27001.listaPuntosNorma[9][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.3. Revisión técnica de aplicaciones después de cambios en la plataforma de operaciones. Cuando se cambian las plataformas de operación, se deben revisar las aplicaciones críticas del negocio, y poner a prueba para asegurar que no haya impacto adverso en las operaciones o seguridad organizacionales. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][5].resultado" @change="datosIso27001.listaPuntosNorma[9][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][5].observaciones" @change="datosIso27001.listaPuntosNorma[9][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.4. Restricciones sobre los cambios de paquetes de software. Se deben desalentar las modificaciones a los paquetes de software, que se deben limitar a los cambios necesarios, y todos los cambios se deben controlar estrictamente. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][6].resultado" @change="datosIso27001.listaPuntosNorma[9][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][6].observaciones" @change="datosIso27001.listaPuntosNorma[9][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.5. Principios de construcción de sistemas de seguros. Se deben establecer, documentar y mantener principios para la organización de sistemas seguros, y aplicarlos a cualquier trabajo de implementación de sistemas de información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][7].resultado" @change="datosIso27001.listaPuntosNorma[9][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][7].observaciones" @change="datosIso27001.listaPuntosNorma[9][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.6. Ambiente de desarrollo seguro. Las organizaciones deben establecer y proteger adecuadamente los ambientes de desarrollo seguros para las tareas de desarrollo e integración de sistemas que comprendan todo el ciclo de vida de desarrollo de sistemas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][8].resultado" @change="datosIso27001.listaPuntosNorma[9][8].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][8].observaciones" @change="datosIso27001.listaPuntosNorma[9][8].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.7. Desarrollo contratado externamente. La organización debe supervisar y hacer seguimiento de la actividad de desarrollo de sistemas subcontratados. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][9].resultado" @change="datosIso27001.listaPuntosNorma[9][9].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][9].observaciones" @change="datosIso27001.listaPuntosNorma[9][9].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.8. Pruebas de seguridad de sistemas. Durante el desarrollo se deben llevar a cabo ensayos de funcionalidad de la seguridad. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][10].resultado" @change="datosIso27001.listaPuntosNorma[9][10].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][10].observaciones" @change="datosIso27001.listaPuntosNorma[9][10].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.2.9. Pruebas de aceptación de sistemas. Para los sistemas de información nuevos, actualizaciones y nuevas versiones se deben establecer programas de ensayo y criterios relacionados. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][11].resultado" @change="datosIso27001.listaPuntosNorma[9][11].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][11].observaciones" @change="datosIso27001.listaPuntosNorma[9][11].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.14.3. DATOS DE ENSAYO. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR LA PROTECCIÓN DE LOS DATOS USADOS PARA ENSAYOS. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.14.3.1. Protección de datos de ensayo. Los datos de ensayo se deben seleccionar, proteger y controlar cuidadosamente. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[9][12].resultado" @change="datosIso27001.listaPuntosNorma[9][12].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[9][12].observaciones" @change="datosIso27001.listaPuntosNorma[9][12].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-11 class="bg-accordion">
                                                    <span class="h6"> A.15 Relaciones con los proveedores </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.15.1. SEGURIDAD DE LA INFORMACIÓN EN LAS RELACIONES CON LOS PROVEEDORES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR LA PROTECCIÓN DE LOS ACTIVOS DE LA ORGANIZACIÓN QUE SEAN ACCESIBLES A LOS PROVEEDORES. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.15.1.1. Política de seguridad de la información para las relaciones con proveedores. Los requisitos de seguridad de la información para mitigar los riesgos asociados con el acceso de proveedores a los activos de la organización se deben acordar con estos y se deben documentar. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[10][0].resultado" @change="datosIso27001.listaPuntosNorma[10][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[10][0].observaciones" @change="datosIso27001.listaPuntosNorma[10][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.15.1.2. Tratamiento de la seguridad dentro de los acuerdos con proveedores. Se deben establecer y acordar todos los requisitos de seguridad de la información pertinentes con cada proveedor que puedan tener acceso, procesar, almacenar, comunicar o suministrar componentes de infraestructura de TI para la información de la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[10][1].resultado" @change="datosIso27001.listaPuntosNorma[10][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[10][1].observaciones" @change="datosIso27001.listaPuntosNorma[10][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.15.1.3. Cadena de suministro de tecnología de información y comunicación. Los acuerdos con proveedores deben incluir requisitos para tratar los riesgos de seguridad de la información asociados con la cadena de suministro de productos y servicios de tecnología de información y comunicación. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[10][2].resultado" @change="datosIso27001.listaPuntosNorma[10][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[10][2].observaciones" @change="datosIso27001.listaPuntosNorma[10][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.15.2. GESTIÓN DE LA PRESTACIÓN DE SERVICIOS DE PROVEEDORES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. MANTENER EL NIVEL ACORDADO DE SEGURIDAD DE LA INFORMACIÓN Y DE PRESTACIÓN DEL SERVICIO EN LÍNEA CON LOS ACUERDOS CON LOS PROVEEDORES. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.15.2.1. Seguimiento y revisión de los servicios de los proveedores. Las organizaciones deben hacer seguimiento, revisar y auditar con regularidad la prestación de servicios de los proveedores. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[10][3].resultado" @change="datosIso27001.listaPuntosNorma[10][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[10][3].observaciones" @change="datosIso27001.listaPuntosNorma[10][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.15.2.2. Gestión de cambios a los servicios de los proveedores. Se deben gestionar los cambios en el suministro de servicios por parte de los proveedores, incluido el mantenimiento y la mejora de las políticas, procedimientos y controles de seguridad de la información existentes, teniendo en cuenta la criticidad de la información, sistemas y procesos del negocio involucrados y la reevaluación de los riesgos. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[10][4].resultado" @change="datosIso27001.listaPuntosNorma[10][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[10][4].observaciones" @change="datosIso27001.listaPuntosNorma[10][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-12 class="bg-accordion">
                                                    <span class="h6"> A.16 Gestión de incidentes de seguridad de la información </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.16.1. GESTIÓN DE INCIDENTES Y MEJORAS EN LA SEGURIDAD DE LA INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR UN ENFOQUE COHERENTE Y EFICAZ PARA LA GESTIÓN DE INCIDENTES DE SEGURIDAD DE LA INFORMACIÓN, INCLUIDA LA COMUNICACIÓN SOBRE EVENTOS DE SEGURIDAD Y DEBILIDAD. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.1. Responsabilidades y procedimientos. Se deben establecer las responsabilidades y procedimientos de gestión para asegurar una respuesta rápida, eficaz y ordenada a los incidentes de seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][0].resultado" @change="datosIso27001.listaPuntosNorma[11][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][0].observaciones" @change="datosIso27001.listaPuntosNorma[11][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.2. Informe de eventos de seguridad de la información. Los eventos de seguridad de la información se deben informar a través de los canales de gestión apropiados tan pronto como sea posible. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][1].resultado" @change="datosIso27001.listaPuntosNorma[11][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][1].observaciones" @change="datosIso27001.listaPuntosNorma[11][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.3. Informe de debilidades de seguridad de la información. Se debe exigir a todos los empleados y contratistas que usan los servicios y sistemas de información de la organización, que se observen e informen cualquier debilidad de seguridad de la información observada o sospechada en los sistemas o servicios. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][2].resultado" @change="datosIso27001.listaPuntosNorma[11][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][2].observaciones" @change="datosIso27001.listaPuntosNorma[11][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.4. Evaluación de eventos de seguridad de la información y decisiones sobre ellos. Los eventos de seguridad de la información se deben evaluar y se debe decidir si se van a clasificar como incidentes de seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][3].resultado" @change="datosIso27001.listaPuntosNorma[11][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][3].observaciones" @change="datosIso27001.listaPuntosNorma[11][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.5. Respuesta a incidentes de seguridad de la información. Se debe dar respuesta a los incidentes de seguridad de la información de acuerdo con procedimientos documentados. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][4].resultado" @change="datosIso27001.listaPuntosNorma[11][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][4].observaciones" @change="datosIso27001.listaPuntosNorma[11][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.6. Aprendizaje obtenido de los incidentes de seguridad de la información. El conocimiento adquirido al analizar y resolver incidentes de seguridad de la información se debe usar para reducir la posibilidad o el impacto de incidentes futuros. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][5].resultado" @change="datosIso27001.listaPuntosNorma[11][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][5].observaciones" @change="datosIso27001.listaPuntosNorma[11][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.16.1.7. Recolección de evidencia. La organización debe definir y aplicar procedimientos para la identificación, recolección, adquisición y preservación de información que pueda servir como evidencia. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[11][6].resultado" @change="datosIso27001.listaPuntosNorma[11][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[11][6].observaciones" @change="datosIso27001.listaPuntosNorma[11][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-13 class="bg-accordion">
                                                    <span class=" h6"> A.17 Aspectos de seguridad de la información de la gestión de la continunidad de negocio </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.17.1. CONTINUIDAD DE SEGURIDAD DE LA INFORMACIÓN </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. LA CONTINUIDAD DE SEGURIDAD DE LA INFORMACIÓN SE DEBE INCLUIR EN LOS SISTEMAS DE GESTIÓN DE LA CONTINUIDAD DE NEGOCIO DE LA ORGANIZACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.17.1.1. Planificación de la continuidad de la seguridad de la información. La organización debe determinar sus requisitos para la seguridad de la información y la continuidad de la gestión de seguridad de la información en situaciones adversas, por ejemplo, durante una crisis o desastres. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[12][0].resultado" @change="datosIso27001.listaPuntosNorma[12][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[12][0].observaciones" @change="datosIso27001.listaPuntosNorma[12][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.17.1.2. Implementación de la continuidad de la seguridad de la información. La organización debe establecer, documentar, implementar y mantener procesos, procedimientos y controles para asegurar el nivel de continuidad requerido para la seguridad de la información durante una situación adversa. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[12][1].resultado" @change="datosIso27001.listaPuntosNorma[12][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[12][1].observaciones" @change="datosIso27001.listaPuntosNorma[12][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.17.1.3. Verificación, revisión y evaluación de la continuidad de la seguridad de la información. La organización debe verificar a intervalos regulares los controles de continuidad de la seguridad de la información implementados con el fin de asegurar que los válidos y eficaces durante situaciones adversas. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[12][2].resultado" @change="datosIso27001.listaPuntosNorma[12][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[12][2].observaciones" @change="datosIso27001.listaPuntosNorma[12][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.17.2. REDUNDANCIA </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURARSE DE LA DISPONIBILIDAD DE INSTALACIONES DE PROCESAMIENTO DE INFORMACIÓN. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.17.2.1. Disponibilidad de instalaciones de procesamiento de información. Las instalaciones de procesamiento de información se deben implementar con redundancia suficiente para cumplir los requisitos de disponibilidad. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[12][3].resultado" @change="datosIso27001.listaPuntosNorma[12][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[12][3].observaciones" @change="datosIso27001.listaPuntosNorma[12][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-14 class="bg-accordion">
                                                    <span class="h6"> A.18 Cumplimiento </span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-14" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-table-simple class="table-custom" mediun responsive bordered outlined fixed hover>
                                                            <b-thead>
                                                                <b-th width="48%">
                                                                    APARTADO ANALIZADO
                                                                </b-th>
                                                                <b-th width="17%">
                                                                    RESULTADO
                                                                </b-th>
                                                                <b-th width="35%">
                                                                    OBSERVACIONES, COMENTARIOS Y EVIDENCIAS
                                                                </b-th>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.18.1. CUMPLIMIENTO DE REQUISITOS LEGALES Y CONTRACTUALES. </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. EVITAR VIOLACIONES DE LAS OBLIGACIONES LEGALES, ESTATUTARIAS, DE REGLAMENTACIÓN O CONTRACTUALES RELACIONADAS CON SEGURIDAD DE LA INFORMACIÓN Y DE CUALQUIER REQUISITO DE SEGURIDAD. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.1.1. Identificación de los requisitos de legislación y contractuales aplicables. Se deben identificar, documentar y mantener actualizados explícitamente todos los requisitos legislativos estatutarios, de reglamentación y contractuales pertinentes, y el enfoque de la organización para cada sistema de información y para la organización. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][0].resultado" @change="datosIso27001.listaPuntosNorma[13][0].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][0].observaciones" @change="datosIso27001.listaPuntosNorma[13][0].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.1.2. Derechos de Propiedad Intelectual. Se deben implementar procedimientos apropiados para asegurar el cumplimiento de los requisitos legislativos, de reglamentación y contractuales relacionados con los derechos de propiedad intelectual y el uso de productos de software licenciados. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][1].resultado" @change="datosIso27001.listaPuntosNorma[13][1].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][1].observaciones" @change="datosIso27001.listaPuntosNorma[13][1].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.1.3. Protección de registros. Los registros se deben proteger contra pérdida, destrucción, falsificación, acceso no autorizado y liberación no autorizada, de acuerdo con los requisitos legislativos, de reglamentación, contractuales y de negocio. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][2].resultado" @change="datosIso27001.listaPuntosNorma[13][2].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][2].observaciones" @change="datosIso27001.listaPuntosNorma[13][2].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.1.4. Privacidad y protección de la información identificable personalmente. Se deben asegurar la privacidad y la protección de la información identificable personalmente, como se exige en la legislación y la reglamentación pertinentes, cuando sea aplicable. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][3].resultado" @change="datosIso27001.listaPuntosNorma[13][3].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][3].observaciones" @change="datosIso27001.listaPuntosNorma[13][3].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.1.5. Reglamentación de Controles Criptográficos. Se deben usar controles criptográficos, en cumplimiento de todos los acuerdos </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][4].resultado" @change="datosIso27001.listaPuntosNorma[13][4].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][4].observaciones" @change="datosIso27001.listaPuntosNorma[13][4].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        A.18.2. REVISIONES DE SEGURIDAD DE LA INFORMACIÓN </b-td>
                                                                </b-tr>
                                                                <b-tr class="tr-bold">
                                                                    <b-td colspan="3">
                                                                        OBJETIVO. ASEGURAR QUE LA SEGURIDAD DE LA INFORMACIÓN SE IMPLEMENTE Y OPERE DE ACUERDO CON LAS POLÍTICAS Y PROCEDIMIENTO ORGANIZACIONALES. </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.2.1. Revisión independiente de la seguridad de la información. El enfoque de la organización para la gestión de la seguridad de la información y su implementación (es decir, los objetivos de control, los controles, la políticas, los procesos y los procedimientos para seguridad de la información se deben revisar independientemente a intervalos planificados o cuando ocurran cambios significativos. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][5].resultado" @change="datosIso27001.listaPuntosNorma[13][5].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][5].observaciones" @change="datosIso27001.listaPuntosNorma[13][5].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.2.2. Cumplimiento con las políticas y normas de seguridad. Los directores deben revisar con regularidad el cumplimiento del procesamiento y procedimientos de información dentro de su área de responsabilidad, con las políticas y normas de seguridad apropiadas y cualquier otro requisito de seguridad. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][6].resultado" @change="datosIso27001.listaPuntosNorma[13][6].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][6].observaciones" @change="datosIso27001.listaPuntosNorma[13][6].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td>
                                                                        A.18.2.3. Revisión del Cumplimiento Técnico. Los Sistemas de información se deben revisar con regularidad para determinar el cumplimiento con las políticas y normas de seguridad de la información. </b-td>
                                                                    <b-td>
                                                                        <b-form-select :value="datosIso27001.listaPuntosNorma[13][7].resultado" @change="datosIso27001.listaPuntosNorma[13][7].resultado = $event" :options="comboResultado">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-form-textarea :value="datosIso27001.listaPuntosNorma[13][7].observaciones" @change="datosIso27001.listaPuntosNorma[13][7].observaciones = $event" rows="4" max-rows="6" placeholder="Escriba aquí..."></b-form-textarea>
                                                                    </b-td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                    </div>
                                </b-col>

                                <b-col class="my-2 text-center" md="12">
                                    <b-button variant="dark" class=" mr-2" :to="{name: 'Declaración de aplicabilidad'}">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="$route.params.id && checkPermissions('005-27001-DEC','u') == 1 " :disabled="disabled"  variant="success" class=" mr-2" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                      <b-button v-if="!$route.params.id && checkPermissions('005-27001-DEC','c') == 1 " :disabled="disabled"  variant="success" class=" mr-2" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>
                <CCardFooter>

                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            comboResultado: [{
                value: 1,
                text: 'OK'
            }, {
                value: 2,
                text: 'NA'
            }, ],
            datosIso27001: {
                idSeguridadInformacion: '',
                fechaAuditoria: moment().format('YYYY-MM-DD'),
                autor: '',
                auditorJefe: '',
                auditor: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                listaPuntosNorma: [
                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '5',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '5',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '6',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '7',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '9',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '8',
                            posicion: '10',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '9',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '10',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '11',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '12',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '13',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '9',
                            posicion: '14',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '10',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '10',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '9',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '10',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '11',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '12',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '13',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '14',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '11',
                            posicion: '15',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '9',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '10',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '11',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '12',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '13',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '12',
                            posicion: '14',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '13',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '9',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '10',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '11',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '12',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '14',
                            posicion: '13',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '15',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '15',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '15',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '15',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '15',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '16',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '17',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '17',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '17',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '17',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],

                    [{
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '1',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '2',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '3',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '4',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '5',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '6',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '7',
                            resultado: 1,
                            observaciones: ''
                        },
                        {
                            idSeguridadInformacionPunto: '',
                            idSeguridadInformacion: '',
                            identificador: '18',
                            posicion: '8',
                            resultado: 1,
                            observaciones: ''
                        },
                    ],
                ],
            },

            disabled: false,
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarSeguridadInformacionPuntos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-seguridad-informacion-puntos", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idSeguridadInformacion: me.$route.params.id,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.datosIso27001.idSeguridadInformacion = response.data[0].idSeguridadInformacion;
                    me.datosIso27001.fechaAuditoria = response.data[0].fechaAuditoria;
                    me.datosIso27001.auditor = response.data[0].auditor;
                    me.datosIso27001.autor = response.data[0].autor;
                    me.datosIso27001.auditorJefe = response.data[0].auditorJefe;
                    me.datosIso27001.ejercicio = response.data[0].ejercicio;

                    let posicion;

                    for (const i in response.data) {
                        if (response.data[i].identificador == 5) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[0][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[0][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[0][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[0][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[0][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 6) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[1][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[1][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[1][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[1][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[1][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 7) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[2][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[2][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[2][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[2][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[2][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 8) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[3][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[3][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[3][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[3][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[3][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 9) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[4][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[4][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[4][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[4][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[4][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 10) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[5][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[5][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[5][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[5][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[5][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 11) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[6][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[6][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[6][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[6][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[6][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 12) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[7][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[7][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[7][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[7][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[7][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 13) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[8][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[8][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[8][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[8][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[8][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 14) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[9][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[9][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[9][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[9][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[9][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 15) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[10][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[10][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[10][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[10][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[10][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 16) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[11][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[11][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[11][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[11][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[11][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 17) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[12][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[12][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[12][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[12][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[12][posicion].observaciones = response.data[i].observaciones;
                        }
                        if (response.data[i].identificador == 18) {
                            posicion = parseInt(response.data[i].posicion) - 1;
                            me.datosIso27001.listaPuntosNorma[13][posicion].idSeguridadInformacionPunto = response.data[i].idSeguridadInformacionPunto;
                            me.datosIso27001.listaPuntosNorma[13][posicion].idSeguridadInformacion = response.data[i].idSeguridadInformacion;
                            me.datosIso27001.listaPuntosNorma[13][posicion].identificador = response.data[i].identificador;
                            me.datosIso27001.listaPuntosNorma[13][posicion].resultado = response.data[i].resultado;
                            me.datosIso27001.listaPuntosNorma[13][posicion].observaciones = response.data[i].observaciones;
                        }

                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarIso27001() {
            let me = this;

            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-seguridad-informacion", {
                        datosIso27001: me.datosIso27001,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.$router.push({
                        name: 'Declaración de aplicabilidad',
                    });
                    me.disabled = false;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            if (this.$route.params.id != null) {
                this.listarSeguridadInformacionPuntos();
            }
        }
    }

}
</script>

<style>
.tr-bold {
    font-weight: bold;
}
</style>
